<template>
  <v-app-bar class="black" app clipped-left elevation="1">
    <v-spacer></v-spacer>
    <LanguageSelect></LanguageSelect>
  </v-app-bar>
</template>

<script>
import LanguageSelect from "./LanguageSelect.vue";
export default {
  name: "Appbar",

  components: {
    LanguageSelect,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.v-application .elevation-1 {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}
</style>