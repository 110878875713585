<template>
  <v-col :offset-md="offset_md">
    <div :style="{ height: imageWidth + 'px' }"></div>
    <v-row>
      <container style="margin: auto;">
        <v-col>
            <v-img class="rounded-xl ma-2" max-height="128" max-width="128"
            src="@/assets/images/opiny-icon-512px.jpg"></v-img>
          
          <br>
          <div :style="{ width: maxWidthTitle }">
            <p class="download-title">{{ $t('download-title') }}</p>
          </div>
          <br>
          <p class="download">{{ $t('download') }}</p>
        </v-col>
        <Download></Download>
      </container>
      <v-col v-if="showRowImage" md="1"></v-col>
      <div v-if="showRowImage" :style="{ maxWidth: phoneWidth, minHeight: phoneHeight }">
        <Slide></Slide>
      </div>
      <v-spacer v-if="showRowImage"></v-spacer>
    </v-row>
    <div v-if="showColumnImage" style="height: 128px"></div>
    <div v-if="showColumnImage" :style="{ maxWidth: phoneWidth, minHeight: phoneHeight }">
      <Slide></Slide>
    </div>
    <div v-if="showColumnImage" style="height: 96px"></div>
  </v-col>
</template>

<script>
import Download from "./Download.vue";
import Slide from "./Slide.vue";
export default {
  components: { Download, Slide },
  name: "Home",
  computed: {
    maxWidthTitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "90%";
        case "md":
        case "lg":
        case "xl":
        default:
          return "512px";
      }
    },
    showRowImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    showColumnImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        case "md":
        case "lg":
        case "xl":
        default:
          return false;
      }
    },
    offset_md() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
        case "md":
          return 1;
        case "lg":
        case "xl":
        default:
          return 2;
      }
    },
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 32;
        case "md":
          return 48;
        case "lg":
          return 64;
        case "xl":
          return 64;
        default:
          return 64;
      }
    },
    phoneWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return '356px';
        case "md":
          return '356px';
        case "lg":
          return '356px';
        case "xl":
          return '356px';
        default:
          return '256px';
      }
    },
    phoneHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return '356px';
        case "md":
          return '356px';
        case "lg":
          return '356px';
        case "xl":
          return '356px';
        default:
          return '256px';
      }
    },
  },
};
</script>

<style scoped>
.download-title {
  font-size: 36px;
  font-weight: normal;
}

.download {
  font-size: 26px;
  font-weight: 500;
}
</style>