<template>
  <v-carousel hide-delimiters show-arrows-on-hover show-arrows="false" cycle height="750px">
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" reverse-transition="fade-transition"
      transition="fade-transition">
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  name: "Slide",
  data: () => ({
    items: [
      {
        src: require("@/assets/images/en-6.5-1.jpg"),
      },
      {
        src: require("@/assets/images/en-6.5-2.jpg"),
      },
      {
        src: require("@/assets/images/en-6.5-3.jpg"),
      },
    ],
  })
};
</script>

<style>
a {
  text-decoration: none;
  color: white !important;
}

.v-window__container {
 height: 0;
}
</style>

