<template>
  <v-footer app inset padless absolute width="auto">
    <v-card class="flex" flat tile>
      <v-card-title class="grey darken-4">
        <div style="color:white" :class="[`text-caption`, active && `mb-4`]">
          <a href="https://help.opiny.net/terms">{{ $t('terms') }}</a>
           | 
          <a href="https://help.opiny.net/privacy-policy">{{ $t('privacy') }}</a>
        </div>

        <v-spacer></v-spacer>

        <v-btn v-on:click="launchPage()" v-for="icon in icons" :key="icon" class="mx-4" light icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",

  data: () => ({
    icons: ["mdi-instagram"],
  }),
  methods: {
    launchPage: function() {
      window.open('https://www.instagram.com/opiny_app', '_blank');
    }
  }
};
</script>

<style>
  a { 
    text-decoration: none;
    color: white !important;
  }
</style>