<template>
  <v-app theme-dark style="background-color:black; color:white">
    <Appbar></Appbar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid color>
        <!-- If using vue-router -->
        <router-view> </router-view>
      </v-container>
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Appbar from "./components/Appbar";
import Footer from "./components/Footer";

export default {
  name: "App",

  computed: {
    md() {
      return 10;
    },
    offset_md() {
      return 0;
    },
  },
  components: {
    Appbar,
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>
