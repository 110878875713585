<template>
  <div class="download-root">
    <tr>
      <th>
        <a :href="appStoreLink">
          <img
            :style="{ width: imageWidth + 'px' }"
            src="../assets/images/ios-store-badge.png"
            v-bind:alt="$t('play-store-alt')"
          />
        </a>
      </th>
      <th>
        <a :href="playStoreLink">
          <img
            :style="{ width: imageWidth + 'px' }"
            src="../assets/images/android-store-badge.png"
            v-bind:alt="$t('play-store-alt')"
          />
        </a>
      </th>
    </tr>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      appStoreLink: "https://apps.apple.com/app/1659681834",
      playStoreLink:
        "https://play.google.com/store/apps/details?id=com.appopiny.opiny"
    };
  },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return 172;
        case "md":
          return 186;
        case "lg":
          return 256;
        case "xl":
          return 256;
        default:
          return 256;
      }
    },
  },
};
</script>

<style scoped>
.download-root {
  margin: auto;
}
.theme--light.v-application {
    color: white !important; 
} 
</style>
